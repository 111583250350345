@import "bootstrap/dist/css/bootstrap.css";

.main {
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 77px); */
    padding-top: calc(10vh);
    max-width: 95%;
}

.plate {
    background-color: #FBDA06;
    text-align: center;
    font-size: larger;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    align-self: center;
    border-radius: 5px;
}

.fontlarge {
    font-weight: 700;
    font-family: Inter, system-ui, sans-serif;
    font-size: 2.5rem;
    line-height: 1.2;
}

.fontsmall {
    font-weight: 400;
    font-family: Inter, system-ui, sans-serif;
    font-size: 1.25rem;
    line-height: 1.6;
    color:rgb(107, 107, 107);
}

.sellingpoints {
    padding-right: auto;
    padding-left: auto;
    align-self: center;
    width: 100%;
    max-width: 1000px;
}