@import "bootstrap/dist/css/bootstrap.css";

.App {
  display: flex;
  padding-top: 1vh;
  padding-bottom: 1vh;
  /* height: calc(100vh - 77px); */
  align-items: center;
  text-align: center;
  justify-content: center;
}

.Background {
  height: 100vh;
  width: 100vw;
}

.nav {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  padding-right: 5%;
  padding-left: 5%;
}
