@import "bootstrap/dist/css/bootstrap.css";

.main {
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 77px); */
    padding-top: calc(10vh);
    width: 95%;
}

.wrapper {
    width: 100%;
}

.items {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    text-align: left;
    width: 95%
}

#plate {
    background-color: #FBDA06;
    text-align: center;
    font-size: larger;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    align-self: center;
    border-radius: 5px;
}

.numberplate {
    background-color: #FBDA06;
    text-align: center;
    font-size: larger;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    align-self: center;
    border-radius: 5px;
    max-width: 140px;
}

.greytext {
    color: grey;
}

.loading {
    display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.red-text {
    color: red;
}

.insights {

}