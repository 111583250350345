@import "bootstrap/dist/css/bootstrap.css";

.items {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    text-align: left;
    /* width: 95% */
}

.plate {
    background-color: #FBDA06;
    text-align: center;
    font-size: larger;
    font-weight: bold;
    text-transform: uppercase;
    width: 60%;
    align-self: center;
    border-radius: 5px;
}

.greytext {
    color: #707070;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}