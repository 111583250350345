.article {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    padding: 20px;
  }

h1 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}